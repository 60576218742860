<template>
  <div v-show="readyToRender" id="content" :data-elbglobals="pageType">
    <TheJsonLd/>
    <AppBanner v-if="!showBlankPage"/>
    <AlertBanner v-if="showAppBanner" :text="translate('alert_message_preview_mode')"/>
    <TheHeader v-if="!showBlankPage"/>
    <main id="page-template">
      <TheCmsTemplate v-if="done" :page-id="cmsPageId" :key="cmsPageId"/>
    </main>
    <LazyComponent id="footer" v-if="!showBlankPage" :delay="1000">
      <TheFooter/>
    </LazyComponent>
  </div>
</template>

<script>
import { useRecommendationStore } from '@/stores/recommendations'
import pageMixin from '@/components/basic/mixins/pageMixin'
import TheHeader from '@/components/basic/organisms/TheHeader'
import TheCmsTemplate from '@/components/cms/templates/TheCmsTemplate'
import TheJsonLd from '@/components/cms/atoms/TheJsonLd'
import { useCustomerStore } from '@/stores/customer'
import { mapActions, mapState } from 'pinia'
import { useCmsPagesStore } from '@/stores/cmsPages'

const LazyComponent = () => import('@/components/basic/atoms/LazyComponent.vue')
const AppBanner = () => import('@/components/basic/molecules/AppBanner')
const AlertBanner = () => import('@/components/basic/atoms/AlertBanner')
const TheFooter = () => import('@/components/basic/organisms/TheFooter')

const STATUS = {
  DISABLED: 1,
  ENABLED: 2
}

export default {
  mixins: [pageMixin],
  components: {
    TheJsonLd,
    TheCmsTemplate,
    AppBanner,
    AlertBanner,
    TheHeader,
    LazyComponent,
    TheFooter
  },
  data () {
    return {
      cmsPageId: undefined,
      done: false
    }
  },
  computed: {
    ...mapState(useCustomerStore, ['customerIsAdmin']),
    ...mapState(useCmsPagesStore, ['page']),
    metaOnCreate () {
      return false
    },
    title () {
      return this.page.title ?? this.page.name ?? ''
    },
    meta () {
      return {
        title: this.title,
        description: this.page.meta_description,
        robots: this.page.robots
      }
    },
    showAppBanner () {
      if (this.page.page_id) {
        return this.page.status === STATUS.DISABLED && this.customerIsAdmin
      }
      return false
    },
    showBlankPage () {
      return location.pathname.endsWith('-bp')
    },
    pageType () {
      return this.$route.name === 'home' ? 'page_type:home' : 'page_type:cms'
    }
  },
  methods: {
    ...mapActions(useCustomerStore, ['waitUntilPending']),
    ...mapActions(useCmsPagesStore, ['fetchCmsPage']),
    async fetchPage (pageId) {
      try {
        this.done = false

        this.cmsPageId = pageId
        await this.fetchCmsPage(pageId)

        await this.checkPageStatus()

        this.done = true
      } catch (e) {
        await this.$router.push('/error')
      }
    },
    async checkPageStatus () {
      if (this.page.status === STATUS.ENABLED) return

      await this.waitUntilPending()
      if (this.customerIsAdmin) return

      await this.$router.push('/error')
    }
  },
  beforeRouteEnter ({ params: { id } }, _, next) {
    next((vm) => {
      vm.fetchPage(id ?? null)
    })
  },
  beforeRouteUpdate ({ params: { id } }, _, next) {
    this.fetchPage(id ?? null)
    next()
  },
  beforeCreate () {
    const recommendations = useRecommendationStore()
    recommendations.fetch()
  }
}
</script>
